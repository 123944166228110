import React from 'react';
import './EmployeeOverview.css';

const EmployeeOverview = () => {
  return (
    <div>
      <p> test </p>
    </div>
  );
};

export default EmployeeOverview;
