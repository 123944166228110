import React from 'react';
import DashboardNavbar from '../../components/DashboardNavbar/DashboardNavbar';
import EmployeeOverview from '../../components/EmployeeOverview/EmoloyeeOverview';
import './Overview.css';

const Dashboard = () => {
  return (
    <div>
      <DashboardNavbar />
      <div className='content'>
        <EmployeeOverview />  
      </div>
    </div>
  )
};

export default Dashboard;
