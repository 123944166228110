import React from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'; 
import './DashboardNavbar.css'; 

const DashboardNavbar = () => {
  return (
    <div className="dashboard-navbar">
      {/* Menu component for the burger menu */}
      <Menu left>
        <a className="menu-item" href="/dashboard">Home</a>
        <a className="menu-item" href="/dashboard/profile">Profile</a>
        <a className="menu-item" href="/dashboard/settings">Settings</a>
        <a className="menu-item" href="/logout">Logout</a>
      </Menu>
      {/* Navbar links for larger screens */}
      <div className="navbar-links">
        <Link className="navbar-item" to="/dashboard">Home</Link>
        <Link className="navbar-item" to="/dashboard/profile">Profile</Link>
        <Link className="navbar-item" to="/dashboard/settings">Settings</Link>
        <Link className="navbar-item" to="/logout">Logout</Link>
      </div>
    </div>
  );
};

export default DashboardNavbar;
