import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './BurgerMenu.css';

const BurgerMenu = () => {
    return (
        <div className="burger-menu-container">
            <Menu>
                <a className="burger-menu-item" href="/">
                    Home
                </a>

                <a className="burger-menu-item" href="/about">
                    About
                </a>

                <a className="burger-menu-item" href="/services">
                    Services
                </a>

                <a className="burger-menu-item" href="/contact">
                    Contact us
                </a>
            </Menu>
        </div>
    );
};

export default BurgerMenu;
